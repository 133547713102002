import { SortingFilters, TeamFilters } from './components/SortingFilters';

import { GeoDynamicContent } from '~/src/components/GeoDynamicContent';

window.addEventListener('DOMContentLoaded', () => {
  console.log('🌕 hello');
  GeoDynamicContent();
  SortingFilters();
  TeamFilters();
});
