export const constants = {
  latinAmerica: [
    "VE",
    "UY",
    "SR",
    "PY",
    "PE",
    "GY",
    "GF",
    "EC",
    "CO",
    "CL",
    "BR",
    "BO",
    "AR",
    "SV",
    "PR",
    "PA",
    "NI",
    "MX",
    "MQ",
    "MF",
    "HT",
    "HN",
    "GT",
    "GP",
    "DO",
    "CU",
    "CR",
    "BZ",
    "BL",
  ],
};
