export function SortingFilters() {
  // create a new Library instance and store it in a variable called "projectsGrid"
  const projectsGrid = new window.FsLibrary('.team-list');

  // define our filter group(s)
  const myFilters = [
    {
      filterWrapper: '.filter-buttons-wrapper-themes',
      filterType: 'exclusive',
      filterByClass: '.filter-theme',
    },
    {
      filterWrapper: '.filter-buttons-wrapper-region',
      filterType: 'exclusive',
      filterByClass: '.filter-region',
    },
  ];
  // run filter on our instance
  projectsGrid.filter({
    filterArray: myFilters, // the filter group name we defined
    activeClass: 'js-tag-active', // the active class we give to our buttons
    animation: {
      enable: true,
      duration: 200,
      easing: 'ease-out',
      effects: 'fade translate(0px,20px)',
    },
  });

  const regionFilterText = document.querySelector('.region-filter-text');
  const regionFilters = document.querySelectorAll('.publication-button.drop');

  function changeFilterText(e) {
    if (regionFilterText.innerText === e.currentTarget.firstChild.innerHTML) {
      regionFilterText.innerText = 'all';
    } else {
      regionFilterText.innerText = e.currentTarget.firstChild.innerHTML;
    }
  }

  regionFilters.forEach(function (el) {
    el.addEventListener('click', changeFilterText);
  });

  document.querySelector('.filter-button-all').click();

  projectsGrid.sort({
    sortTrigger: '.sort-not-reverse',
    activeClass: 'tag-active',
  });
}

export function TeamFilters() {
  if (window.location.pathname === '/portfolio') return;
  // Get values for each element with .filter-by-text class within the button and store in a variable
  const filterByTextElements =
    document.getElementsByClassName('filter-by-text');

  // For each filter by text element
  for (element of filterByTextElements) {
    // Get each element text and store in a variable
    const elementText = element.innerText;
    // set attribute to the parent element dynamically
    element.parentElement.setAttribute('filter-by', elementText);
  }
  const fsComponent = new window.FsLibrary('.team-list');

  const myFilters = [
    {
      filterWrapper: '.filter-buttons-wrapper',
      filterType: 'exclusive',
      filterByClass: '.region-filter-wrap',
    },
    {
      filterWrapper: '.search-wrapper',
      filterType: 'exclusive',
    },
  ];

  fsComponent.filter({
    filterArray: myFilters,
    activeClass: 'tag-active',
    emptyMessage: '.empty-message',
    animation: {
      enable: true,
      duration: 300,
      easing: 'ease-out',
    },
  });

  document.querySelector('.filter-button-all').click();
}
