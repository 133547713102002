import { constants } from '../constants';

export async function GeoDynamicContent() {
  const elements = {
    regionsList: document.querySelectorAll('[data-portfolio-region]'),
    slider: document.querySelector('[data-slider]'),
    slideElements: document.querySelectorAll('[data-slide-element]'),
    slide: document.querySelectorAll('[data-slide-region]'),
    tabs: document.querySelectorAll('[data-tab]'),
    tabsMenu: document.querySelector('[data-menu-tab'),
    newsList: document.querySelector('[data-news-list]'),
    regionalNews: document.querySelectorAll('[data-regional-news]'),
    teamList: document.querySelectorAll('[data-team-region]'),
  };

  const state = {
    user: {
      region: '',
    },
  };

  // Functions
  const fetchUserRegion = async () => {
    const url = `https://api.bigdatacloud.net/data/country-by-ip?key=${process.env.BIGDATACLOUD_API_KEY}`;
    
    const get = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await get.json();
    const { latinAmerica } = constants;

    if (data) {
      if (data.country.name === 'India') {
        state.user.region = data.country.name;
      } else if (latinAmerica.includes(data.country.isoAlpha2)) {
        state.user.region = 'Latin America';
      } else {
        state.user.region = data.country.continents[0].continent;
      }
    }
  };

  const setFilterRegion = (regionsList: NodeListOf<Element>) => {
    if (!regionsList) return;
    const regionsListArr = Array.from(regionsList);
    const { region } = state.user;
    const matchRegion: HTMLElement = regionsListArr.find((el) => {
      return el.getAttribute('filter-by') === region.toLowerCase();
    }) as HTMLElement;
    if (!matchRegion) return;
    matchRegion.click();
  };

  const initializeSlider = () => {
    const fsComponent = new window.FsLibrary('.hero-cms-slider');
    fsComponent.slider({
      sliderComponent: '.hero-slider',
    });
  };

  const sortSliderByRegion = () => {
    const { slider, slideElements } = elements;
    if (!slider) return;
    const { region } = state.user;
    if (!['Africa', 'Latin America', 'India', 'Europe'].includes(region)) {
      return initializeSlider();
    }

    const elementSlides = Array.from(slideElements);
    const sortRegion = elementSlides.reverse().sort((a) => {
      if (
        a.querySelector('[data-slide-region]')?.innerHTML ===
        region.toLowerCase()
      ) {
        return -1;
      }
      return 1;
    });

    slider.replaceChildren(...sortRegion);
    initializeSlider();
  };

  const filterTabPortfolios = () => {
    const { tabs, tabsMenu } = elements;
    if (!tabs || !tabsMenu) return;
    const tabsArr = Array.from(tabs);
    const tabRegionQuery = '[data-tab-region]';
    const { region } = state.user;
    const originalTabs = tabsArr.slice(0, 9);
    const afterRemovedTabs = originalTabs.filter(
      (el) => el.querySelector(tabRegionQuery)?.innerHTML !== 'Europe'
    );
    if (!region || region === 'Europe') {
      return tabsMenu.replaceChildren(...originalTabs);
    }
    const filteredByRegion: HTMLElement[] = tabsArr.filter((el) => {
      return el.querySelector(tabRegionQuery)?.innerHTML === region;
    }) as HTMLElement[];
    if (region === 'Africa') {
      filteredByRegion[0].click();
      return tabsMenu.replaceChildren(
        ...filteredByRegion,
        ...afterRemovedTabs.slice(0, 5)
      );
    }
    filteredByRegion[0].click();
    tabsMenu.replaceChildren(...filteredByRegion, ...afterRemovedTabs);
  };

  const filterNewsByRegion = () => {
    const { newsList, regionalNews } = elements;
    const { region } = state.user;
    const newsRegionQuery = '[data-news-region]';
    if (!newsList) return;

    const threeRegionalNews = Array.from(regionalNews).filter((el) => {
      return (
        el.querySelector(newsRegionQuery)?.innerHTML === region.toLowerCase()
      );
    });
    if (threeRegionalNews.length < 4) return;
    if (threeRegionalNews.length >= 4) {
      return newsList.replaceChildren(...threeRegionalNews.slice(0, 4));
    }
    newsList.replaceChildren(...threeRegionalNews);
  };

  // Run
  await fetchUserRegion();
  setFilterRegion(elements.regionsList);
  sortSliderByRegion();
  filterTabPortfolios();
  filterNewsByRegion();
  setFilterRegion(elements.teamList);
}
